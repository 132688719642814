import { Component, OnInit, OnDestroy } from '@angular/core';
import { RoomNoService } from '../service/room-no.service';
import { SideBar } from '../shared/component/sidebar/siderbar';
import { KeyCode } from '../shared/const/KeyCode';
import { Router } from '@angular/router';
import { LanguageService } from '../service/language.service';
import { ScreensaverService } from '../screensaver.service';

@Component({
  selector: 'app-yakkan',
  templateUrl: './yakkan.component.html',
  styleUrls: ['./yakkan.component.scss']
})
export class YakkanComponent implements OnInit, OnDestroy {
  room_no = '';
  yakkan = require('./yakkan.json');
  kiyaku = require('./kiyaku.json');
  side_index = 0; // 本文、規約で何P目を表示するかのindex
  side_bar_index = 0; // サイドバーのフォーカスindex
  is_yakkan = true; // 約款か規約どちらを表示するか
  sidebar: SideBar<YakkanSideBarTiTleJpn, YakkanSideBarTitleEng>[] = [
    {
      title: '宿泊約款',
      title_jpn: '宿泊約款',
      title_eng: 'Regulation',
      checked: true,
      key_code: '1',
      focused: false
    },
    {
      title: '利用規約',
      title_jpn: '利用規約',
      title_eng: 'Terms of service',
      checked: false,
      key_code: '2',
      focused: false
    },
    {
      title: 'メニューへ戻る',
      title_jpn: 'メニューへ戻る',
      title_eng: 'Back to menu',
      checked: false,
      key_code: '10',
      focused: false
    }
  ];
  yakkan_name: string;
  constructor(
    private router: Router,
    private roomNoService: RoomNoService,
    private languageService: LanguageService,
    private screensaverService: ScreensaverService
  ) {}
  ngOnInit() {
    document.onkeydown = this.pushButton.bind(this);

    // Languageをサービスしちゃう

    if (this.languageService.language === 'eng') {
      this.yakkan_name = 'REGULATION';

      this.sidebar = this.sidebar.map(d => {
        return {
          title: d.title_eng,
          title_jpn: d.title_jpn,
          title_eng: d.title_eng,
          key_code: d.key_code,
          checked: d.checked,
          focused: d.focused
        };
      });
    } else {
      this.yakkan_name = '宿泊約款';
    }

    // 約款と規約の日本語英語切り替え
    this.yakkan = this.yakkan.map((d: { path: string }) => {
      return {
        path: d.path.replace('jpn', this.languageService.language)
      };
    });

    this.kiyaku = this.kiyaku.map((d: { path: string }) => {
      return {
        path: d.path.replace('jpn', this.languageService.language)
      };
    });
  }
  ngOnDestroy() {
    document.onkeydown = null;
    this.screensaverService.runEvent();
  }

  pushButton(ev: KeyboardEvent) {
    // TODO チャンネルボタン押された時の処理
    const 種別 = this.side_bar_index === 0 ? 'yakkan' : 'kiyaku';
    ev.stopPropagation();
    if (
      ev.keyCode === KeyCode.VK_RIGHT &&
      this.side_index !== this[種別].length - 1
    ) {
      // 横方向のインデックスインクリメント
      console.log(this.side_index);
      this.side_index = this.side_index + 1;
    } else if (ev.keyCode === KeyCode.VK_LEFT && this.side_index !== 0) {
      // 横方向のインデックスデクリメント
      this.side_index = this.side_index - 1;
    } else if (
      ev.keyCode === KeyCode.VK_DOWN &&
      this.side_bar_index !== this.sidebar.length - 1
    ) {
      // 縦方向のインデックスインクリメント
      this.side_bar_index = this.side_bar_index + 1;
    } else if (ev.keyCode === KeyCode.VK_UP && this.side_bar_index !== 0) {
      // 縦方向のインデックスデクリメント
      this.side_bar_index = this.side_bar_index - 1;
    } else if (ev.keyCode === KeyCode.VK_ENTER) {
      this.clickSideBar();
    }
    this.sidebar.forEach(d => (d.checked = false));
    this.sidebar[this.side_bar_index].checked = true;
  }
  clickSideBar() {
    if (
      ['宿泊約款', 'Regulation'].includes(
        this.sidebar[this.side_bar_index].title
      )
    ) {
      this.is_yakkan = true;
      this.side_index = 0;
    } else if (
      ['利用規約', 'Terms of service'].includes(
        this.sidebar[this.side_bar_index].title
      )
    ) {
      this.is_yakkan = false;
      this.side_index = 0;
    } else if (
      ['メニューへ戻る', 'Back to menu'].includes(
        this.sidebar[this.side_bar_index].title
      )
    ) {
      this.roomNoService.source = 'yakkan';
      this.router.navigate(['/room', this.roomNoService.room_no]);
    }
  }
}

declare function require(x: string): any;
type YakkanSideBarTiTleJpn = '宿泊約款' | '利用規約';
type YakkanSideBarTitleEng = 'Regulation' | 'Terms of service';
