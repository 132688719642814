import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { RoomNoService } from '../service/room-no.service';

@Component({
  selector: 'app-remote-contorl',
  templateUrl: './remote-contorl.component.html',
  styleUrls: ['./remote-contorl.component.scss']
})
export class RemoteContorlComponent implements OnInit, AfterViewInit {
  constructor(public roomService: RoomNoService, private el: ElementRef) {}
  element: HTMLElement;

  ngAfterViewInit(): void {
    this.element = this.el.nativeElement;
    this.element.querySelector('span').focus();
  }
  ngOnInit() {}
}
