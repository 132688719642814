import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-hoge',
  templateUrl: './hoge.component.html',
  styleUrls: ['./hoge.component.scss']
})
export class HogeComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  id = '';
  ngOnInit() {
    this.route.paramMap.subscribe(p => {
      const id = p.get('id');
      this.id = id;
    });
  }
}
