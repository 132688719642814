import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { HogeComponent } from './hoge/hoge.component';
import { YakkanComponent } from './yakkan/yakkan.component';
import { AppliListMainComponent } from './appli-list-main/appli-list-main.component';
import { StayInfoComponent } from './stay-info/stay-info.component';
import { HotelInfoComponent } from './hotel-info/hotel-info.component';
import { NearbyInfoComponent } from './nearby-info/nearby-info.component';
import { RemoteContorlComponent } from './remote-contorl/remote-contorl.component';
import { BreakfastComponent } from './breakfast/breakfast.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'index',
  //   pathMatch: 'full'
  // },
  {
    path: 'yakkan',
    component: YakkanComponent
  },
  {
    path: 'room/:room',
    component: IndexComponent
  },
  {
    path: 'room',
    component: IndexComponent
  },
  {
    path: 'hoge/:id',
    component: HogeComponent
  },
  {
    path: 'app-list',
    component: AppliListMainComponent
  },
  {
    path: 'stay-info',
    component: StayInfoComponent
  },
  {
    path: 'hotel-info',
    component: HotelInfoComponent
  },
  {
    path: 'nearby-info',
    component: NearbyInfoComponent
  },
  {
    path: 'remote-control',
    component: RemoteContorlComponent
  },
  {
    path: 'breakfast',
    component: BreakfastComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
