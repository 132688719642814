import { Injectable } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreensaverService {
  subject$ = new Subject();
  constructor() {}

  runEvent() {
    this.subject$.next();
  }

  getObservable() {
    return this.subject$.asObservable();
  }
}
