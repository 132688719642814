export const KeyCode = {
  VK_LEFT: 37,
  VK_UP: 38,
  VK_RIGHT: 39,
  VK_DOWN: 40,
  VK_ENTER: 13,
  VK_0: 49,
  VK_1: 50,
  VK_2: 51,
  VK_3: 52,
  VK_4: 53,
  VK_5: 54,
  VK_6: 55,
  VK_7: 56,
  VK_8: 57,
  VK_9: 58,
  VK_10: 48,
  VK_11: 60
};
