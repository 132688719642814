import { Component, OnInit, OnDestroy } from '@angular/core';
import { RoomNoService } from 'src/app/service/room-no.service';
import { getNowDateObject, getStreamNowDate } from '../../tools/DateNowTime';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/service/language.service';
import { nowDate } from 'src/app/index/nowDate';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  room_no = '';
  now_date_obj: nowDate = getNowDateObject(
    new Date(),
    this.languageService.language
  );
  now_date = getStreamNowDate();
  sub: Subscription;
  constructor(
    private roomNoService: RoomNoService,
    private languageService: LanguageService
  ) {}
  ngOnInit() {
    if (this.languageService.language === 'jpn') {
      this.room_no = `□ こちらのお部屋は ${this.roomNoService.room_no} 号室です。`; // 部屋番号セット
    } else {
      this.room_no = `□ This is room ${this.roomNoService.room_no} .`; // 部屋番号セット
    }
    this.sub = this.now_date.subscribe(d => {
      this.now_date_obj = getNowDateObject(d, this.languageService.language);
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
