import { Component, OnInit, OnDestroy } from '@angular/core';
import { SideBar } from '../shared/component/sidebar/siderbar';
import { RoomNoService } from '../service/room-no.service';
import { KeyCode } from '../shared/const/KeyCode';
import { Router } from '@angular/router';
import { BraviaApiService } from '../service/bravia-api.service';
import { LanguageService } from '../service/language.service';
import {
  getNowDateObject,
  getStreamNowDate,
} from '../shared/tools/DateNowTime';
import { nowDate } from '../index/nowDate';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-appli-list-main',
  templateUrl: './appli-list-main.component.html',
  styleUrls: ['./appli-list-main.component.scss'],
})
export class AppliListMainComponent implements OnInit, OnDestroy {
  focusIndex = 0;
  now_date = getStreamNowDate();
  now_date_obj: nowDate = getNowDateObject(
    new Date(),
    this.languageService.language
  );
  sub: Subscription[] = [];
  sidebar: SideBar<AppListSideBarTiTleJpn, AppListSideBarTiTleEng>[] = [
    {
      title: 'ニュース',
      title_jpn: 'ニュース',
      title_eng: 'NEWS',
      key_code: '1',
      checked: true,
      focused: true,
    },
    {
      title: 'VOD',
      title_jpn: 'VOD',
      title_eng: 'VOD',
      key_code: '2',
      checked: false,
      focused: false,
    },

    {
      title: 'スポーツ',
      title_jpn: 'スポーツ',
      title_eng: 'Sports',
      key_code: '3',
      checked: false,
      focused: false,
    },
    {
      title: 'ミュージック',
      title_jpn: 'ミュージック',
      title_eng: 'Music',
      key_code: '4',
      checked: false,
      focused: false,
    },
    {
      title: 'メニューへ戻る',
      title_jpn: 'メニューへ戻る',
      title_eng: 'Back to menu',
      key_code: '92',
      checked: false,
      focused: false,
    },
  ];
  appListIndex = 0;
  appList: AppliMain[][] = []; // require('./applications.json');
  applistCategory: AppListCategory = require('./category.json');
  appname: string;
  constructor(
    private roomNoService: RoomNoService,
    private router: Router,
    private braviaService: BraviaApiService,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    // Languageをサービスしちゃう
    if (this.languageService.language === 'eng') {
      this.sidebar = this.sidebar.map(d => {
        return {
          title: d.title_eng,
          title_jpn: d.title_jpn,
          title_eng: d.title_eng,
          key_code: d.key_code,
          checked: d.checked,
          focused: d.focused,
        };
      });
      this.appname = 'Applications';
    } else {
      this.appname = 'アプリケーション';
    }
    this.sub.push(
      this.now_date.subscribe(d => {
        this.now_date_obj = getNowDateObject(d, this.languageService.language);
      })
    );
    this.braviaService.getAppList().subscribe(d => {
      console.log(this.applistCategory);
      const getCategory = (category: string) => {
        return d.result[0]
          .filter(e => {
            console.log(e);
            return this.applistCategory[category].includes(e.uri);
          })
          .map(e => {
            e.focused = false;
            return e;
          });
      };
      const news = getCategory('NEWS');
      const vod = getCategory('VOD');
      const sports = getCategory('Sports');
      const music = getCategory('Music');

      this.appList[0] = [...news];
      this.appList[1] = [...vod];
      this.appList[2] = [...sports];
      this.appList[3] = [...music];
    });
  }
  ngOnDestroy() {
    this.sub.forEach(d => d.unsubscribe());
  }
  setFocusIndex(index: number) {
    console.log(index);
    if (
      ['メニューへ戻る', 'Back to menu'].includes(this.sidebar[index].title)
    ) {
      this.router.navigate(['/room', this.roomNoService.room_no]);
    }
    this.focusIndex = index;
    this.sidebar.forEach(d => (d.checked = false));
    this.sidebar[this.focusIndex].checked = true;
    this.appListIndex = index;
  }
  pushButton(ev: KeyboardEvent) {
    // key_codeに一致する要素にfocusをあわせる
    this.setFocusIndex(
      Number(
        this.sidebar.find(d => ev.keyCode === KeyCode[`VK_${d.key_code}`])
          .key_code
      )
    );
  }

  appliMainChangeFocus(i: number) {
    this.appList[this.appListIndex].forEach(d => (d.focused = false));
    this.appList[this.appListIndex][i].focused = true;
  }

  resetAllFocus() {
    this.appList.forEach(d => d.forEach(e => (e.focused = false)));
  }

  changeAppListIndex(i: number) {
    this.appListIndex = i;
  }
  // 起動
  async launchAppli(i: number) {
    if (this.appList[this.appListIndex][i].uri.includes('http')) {
      window.location.href = this.appList[this.appListIndex][i].uri;
    } else {
      await this.braviaService.launchAppli(
        this.appList[this.appListIndex][i].uri
      );
    }
  }

  focus(i: number) {
    this.sidebar.forEach(d => (d.focused = false));
    this.sidebar[i].focused = true;
  }
  blur() {
    this.sidebar.forEach(d => (d.focused = false));
  }
}

type AppListSideBarTiTleJpn = 'VOD' | 'ニュース' | 'スポーツ' | 'ミュージック';

type AppListSideBarTiTleEng = 'VOD' | 'NEWS' | 'Sports' | 'Music';
declare function require(x: string): any;

interface AppListCategory {
  NEWS?: string[];
  VOD?: string[];
  Sports?: string[];
  Music?: string[];
}
