import { Component, OnInit } from '@angular/core';
import { ScreensaverService } from '../app/screensaver.service';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  onScreenSaver = false;
  title = 'hotel-system';
  counter = 0;
  constructor(private screensaverService: ScreensaverService) {}
  obserbable = this.screensaverService.getObservable();
  ngOnInit() {
    // this.screensaverService.init();
    document.onkeydown = this.pushButton.bind(this);

    setInterval(() => {
      if (this.counter > 1800) {
        this.onScreenSaver = true;
      } else {
        this.counter = this.counter + 1;
      }
    }, 1000);
    // of(true).pipe(delay(7000))
    // .subscribe( d => this.onScreenSaver = d);

    this.obserbable.subscribe(() => {
      document.onkeydown = this.pushButton.bind(this);
    });
  }

  pushButton(ev: KeyboardEvent) {
    this.counter = 0;
    this.onScreenSaver = false;
  }
}
