import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoomNoService {
  room_no = '';
  source = ''; // どの画面からindexコンポーネントに戻ったか
  constructor() {}
}
