import { Component, OnInit, Input } from '@angular/core';
import { SideBar } from './siderbar';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() sidebars: SideBar<string, string>[];
  constructor() {}
  ngOnInit() {}
}
