import { Component, OnInit, OnDestroy } from '@angular/core';
import { SideBar } from '../shared/component/sidebar/siderbar';
import { RoomNoService } from '../service/room-no.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  getNowDateObject,
  getStreamNowDate,
} from '../shared/tools/DateNowTime';
import { nowDate } from '../index/nowDate';
import { LanguageService } from '../service/language.service';

@Component({
  selector: 'app-hotel-info',
  templateUrl: './hotel-info.component.html',
  styleUrls: ['./hotel-info.component.scss'],
})
export class HotelInfoComponent implements OnInit, OnDestroy {
  now_date = getStreamNowDate();
  sub: Subscription[] = [];
  now_date_obj: nowDate = getNowDateObject(
    new Date(),
    this.languageService.language
  );
  lang = '';

  focusIndex = 0;
  hotelIndex = 0;
  sidebar: SideBar<HotelInfoSideBarTiTleJpn, HotelInfoSideBarTiTleEng>[] = [
    {
      title: 'コインランドリー',
      title_jpn: 'コインランドリー',
      key_code: '0',
      title_eng: 'Laundromat',
      checked: true,
      focused: true,
    },
    {
      title: '喫煙所',
      title_jpn: '喫煙所',
      key_code: '1',
      title_eng: 'Smoking area',
      checked: false,
      focused: false,
    },
    {
      title: '自動販売機・製氷機',
      title_jpn: '自動販売機・製氷機',
      key_code: '2',
      title_eng: 'vending machine / ice machine',
      checked: false,
      focused: false,
    },
    {
      title: '外貨両替機',
      title_jpn: '外貨両替機',
      key_code: '3',
      title_eng: 'Money exchange',
      checked: false,
      focused: false,
    },
    {
      title: 'メニューへ戻る',
      title_jpn: 'メニューへ戻る',
      title_eng: 'Back to menu',
      key_code: '92',
      checked: false,
      focused: false,
    },
  ];
  appname: string;

  constructor(
    private roomNoService: RoomNoService,
    private router: Router,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    // 現在時刻の表示
    this.sub.push(
      this.now_date.subscribe(d => {
        this.now_date_obj = getNowDateObject(d, this.languageService.language);
      })
    );

    // Languageをサービスしちゃう
    this.lang = this.languageService.language;
    if (this.languageService.language === 'jpn') {
      this.appname = 'ホテル館内案内';
      return;
    } else {
      this.appname = 'Hotel information';
    }
    this.sidebar = this.sidebar.map(d => {
      d.title = d.title_eng;
      return d;
    });
  }
  ngOnDestroy() {
    this.sub.forEach(d => d.unsubscribe());
  }

  setFocusIndex(index: number) {
    if (
      ['メニューへ戻る', 'Back to menu'].includes(this.sidebar[index].title)
    ) {
      this.router.navigate(['/room', this.roomNoService.room_no]);
    }
    this.focusIndex = index;
    this.sidebar.forEach(d => (d.checked = false));
    this.sidebar[this.focusIndex].checked = true;
    this.hotelIndex = index;
  }
}
type HotelInfoSideBarTiTleJpn =
  | '喫煙所'
  | 'コインランドリー'
  | 'レンタサイクル'
  | '自動販売機・製氷機'
  | '外貨両替機'
  | 'メニューへ戻る';

type HotelInfoSideBarTiTleEng =
  | 'Smoking area'
  | 'Laundromat'
  | 'Bicycle rental'
  | 'Money exchange'
  | 'vending machine / ice machine';
