import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  VERSION
} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Iplist } from './iplist';
import { Subscription, forkJoin, combineLatest } from 'rxjs';
import { nowDate } from './nowDate';
import { BraviaApiService } from '../service/bravia-api.service';
import { RoomNoService } from '../service/room-no.service';
import {
  getNowDateObject,
  getStreamNowDate
} from '../shared/tools/DateNowTime';
import { LanguageService } from '../service/language.service';
import { WeatherService } from '../service/weather/weather.service';
declare function require(x: string): any;

declare var Skycons: any;
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  animations: [
    //animation triggers go here
  ]
})
export class IndexComponent implements OnInit, OnDestroy, AfterViewInit {
  isEng: boolean;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private braviaService: BraviaApiService,
    private roomNoService: RoomNoService,
    private languageService: LanguageService,
    private weatherService: WeatherService
  ) {}
  lang = '';
  ip = '';
  ssid = '';
  capacity: number;
  qr = '';
  password = '';
  room_no = '';
  sub: Subscription[] = [];
  now_date = getStreamNowDate();
  icons: Icon[] = [
    {
      uri: 'stay-info',
      icon: 'assets/icons/jpn/top_icon_01.png',
      link: 'hoge',
      focused: true
    },
    {
      uri: 'hotel-info',
      icon: 'assets/icons/jpn/top_icon_02.png',
      link: 'mirror',
      focused: false
    },
    // {
    //   uri: 'sendHomeKey',
    //   icon: 'assets/icons/jpn/top_icon_03.png',
    //   link: 'wifi',
    //   focused: false
    // },
    // {
    //   uri: 'app-list',
    //   icon: 'assets/icons/jpn/top_icon_04.png',
    //   link: 'wifi',
    //   focused: false
    // },
    {
      uri: 'breakfast',
      icon: 'assets/icons/jpn/top_icon_05.png',
      link: 'wifi',
      focused: false
    },
    {
      uri: 'nearby-info',
      icon: 'assets/icons/jpn/top_icon_06.png',
      link: 'wifi',
      focused: false
    },
    {
      uri: 'yakkan',
      icon: 'assets/icons/jpn/top_icon_07.png',
      link: 'wifi',
      focused: false
    },
    // {
    //   uri:
    //     'com.sony.dtv.screnmirroring.com.screnmirroring.com.StartScreenMirroringHomeActivity',
    //   icon: 'assets/icons/jpn/top_icon_08.png',
    //   link: 'wifi',
    //   focused: false
    // },
    {
      uri: 'changeLang',
      icon: 'assets/icons/jpn/top_icon_09.png',
      link: 'wifi',
      focused: false
    }
  ];

  now_date_obj: nowDate = getNowDateObject(
    new Date(),
    this.languageService.language
  );
  today_rainy: string; // 今日の降水確率
  tommorow_rainy: string; // 明日の降水確率
  today_temp: string;
  tommorow_temp: string;

  ngOnInit() {
    if (this.languageService.language === 'eng') {
      this.isEng = true;
    }

    /*    particlesJS.load('particles-js', '/assets/particles.json', null);*/
    this.changeIconForLang(this.languageService.language);
    this.weatherService.getWeather().subscribe(d => {
      this.today_rainy =
        Math.round(d.daily.data[0].precipProbability * 10) * 10 + '%';
      this.tommorow_rainy =
        Math.round(d.daily.data[1].precipProbability * 10) * 10 + '%';
      this.today_temp =
        Math.round(d.daily.data[0].temperatureHigh) +
        '℃ / ' +
        Math.round(d.daily.data[0].temperatureLow) +
        '℃';
      this.tommorow_temp =
        Math.round(d.daily.data[1].temperatureHigh) +
        '℃ / ' +
        Math.round(d.daily.data[1].temperatureLow) +
        '℃';
      const skycons = new Skycons({ color: 'white' });
      skycons.add(
        'today',
        Skycons[
          d.daily.data[0].icon
            .toLocaleUpperCase()
            .split('-')
            .join('_')
        ]
      );
      skycons.add(
        'tomorrow',
        Skycons[
          d.daily.data[1].icon
            .toLocaleUpperCase()
            .split('-')
            .join('_')
        ]
      );
      skycons.play();
    });
    // 現在時刻の表示
    this.sub.push(
      this.now_date.subscribe(d => {
        this.now_date_obj = getNowDateObject(d, this.languageService.language);
      })
    );
    // this.sub.push(
    combineLatest(this.route.paramMap, this.route.queryParams).subscribe(d => {
      const room_no = d[0].get('room');
      const ssid = d[1]['ssid'];
      if (!room_no) {
        // alert('部屋番号が設定されていません');
        // TODO エラーページに飛ばせばええんちゃう
      }
      const ip_list: Iplist[] = require('./iplist.json');
      const ip = ip_list.find(list => list.room_no === room_no);
      if (!ip) {
        // alert('存在しない部屋番号です');
        // TODO エラーページに飛ばせばええんちゃう
      }
      this.room_no = room_no; // 部屋番号
      this.ssid = ssid; // ssid

      this.capacity = 0;
      this.roomNoService.room_no = room_no; // 部屋番号をserviceに保存
      this.password = d[1]['key'];
      if (this.password) {
        localStorage.setItem('password', this.password);
        localStorage.setItem('ssid', this.ssid);
      } else {
        this.password = localStorage.getItem('password');
        this.ssid = localStorage.getItem('ssid');
      }
      this.qr = `WIFI:S:${this.ssid};T:WPA;P:${this.password};;`;
    });
    this.sub.push(
      // 指定言語によるアイコンの切り替え
      this.languageService.getObservable().subscribe(l => {
        this.changeIconForLang(l);
      })
    );
  }

  changeIconForLang(l: string) {
    this.icons = this.icons.map(d => {
      const split_path = d.icon.split('/');
      split_path[2] = l;
      d.icon = split_path.join('/');
      return d;
    });
  }

  ngAfterViewInit() {
    (<any>document.querySelector('.fuga')).focus();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.forEach(d => d.unsubscribe());
    }
  }

  async aClick(i: number) {
    // なぜか他画面から戻った時にフォーカスが当たっている位置のクリックイベントが発火してしまう
    if (this.roomNoService.source !== '') {
      this.roomNoService.source = '';
      return;
    }
    if (/^com./.test(this.icons[i].uri)) {
      await this.braviaService.launchAppli(this.icons[i].uri);
    } else if (/^http./.test(this.icons[i].uri)) {
      window.open(this.icons[i].uri);
    } else if (/^sendHomeKey/.test(this.icons[i].uri)) {
      this.braviaService.sendHomeKey();
    } else if (/^changeLang/.test(this.icons[i].uri)) {
      this.changeLang();
    } else {
      this.router.navigate([this.icons[i].uri]);
    }
  }

  changeIcon(i: number) {
    this.icons[i].focused = true;
  }
  blur() {
    this.icons.forEach(d => (d.focused = false));
  }
  changeLang() {
    if (this.languageService.language === 'jpn') {
      this.isEng = true;
      this.languageService.setEn();
    } else if (this.languageService.language === 'eng') {
      this.isEng = false;
      this.languageService.setJa();
    }
  }
}
