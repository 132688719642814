import { Observable } from 'rxjs';

export function getNowDateObject(d: Date, lang: string) {
  const day_of_week = () => {
    if (lang === 'jpn') {
      return ['日', '月', '火', '水', '木', '金', '土'];
    } else if (lang === 'eng') {
      return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    }
  };
  return {
    year: d.getFullYear().toString(),
    month: ('00' + (d.getMonth() + 1)).slice(-2),
    day: ('00' + d.getDate()).slice(-2),
    hhmm:
      ('0' + d.getHours()).slice(-2) + '：' + ('0' + d.getMinutes()).slice(-2),

    day_of_week: day_of_week()[d.getDay()]
  };
}

export function getStreamNowDate() {
  return new Observable<Date>(o => {
    setInterval(() => {
      o.next(new Date());
    }, 1000);
  });
}
