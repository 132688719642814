import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  subject = new Subject<'jpn' | 'eng'>();
  language: 'jpn' | 'eng' = 'jpn';
  constructor() {}
  getObservable() {
    return this.subject.asObservable();
  }
  setJa() {
    this.language = 'jpn';
    this.subject.next('jpn');
  }

  setEn() {
    this.language = 'eng';
    this.subject.next('eng');
  }
}
