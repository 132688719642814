import { Component, OnInit, OnDestroy } from '@angular/core';
import { SideBar } from '../shared/component/sidebar/siderbar';
import { RoomNoService } from '../service/room-no.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {
  getNowDateObject,
  getStreamNowDate
} from '../shared/tools/DateNowTime';
import { nowDate } from '../index/nowDate';
import { LanguageService } from '../service/language.service';

@Component({
  selector: 'app-stay-info',
  templateUrl: './stay-info.component.html',
  styleUrls: ['./stay-info.component.scss']
})
export class StayInfoComponent implements OnInit, OnDestroy {
  now_date = getStreamNowDate();
  sub: Subscription[] = [];
  now_date_obj: nowDate = getNowDateObject(
    new Date(),
    this.languageService.language
  );

  focusIndex = 0;
  rentalIndex = 0;

  lang: 'jpn' | 'eng';
  sidebar: SideBar<AppListSideBarTiTleJpn, AppListSideBarTiTleEng>[] = [
    {
      title: '貸し出し品一覧',
      title_jpn: '貸し出し品一覧',
      title_eng: 'Borrowing Items',
      key_code: '1',
      checked: true,
      focused: true
    },
    {
      title: 'モーニングコール',
      title_jpn: 'モーニングコール',
      title_eng: 'Wake-up call',
      key_code: '2',
      checked: false,
      focused: false
    },
    {
      title: 'メニューへ戻る',
      title_jpn: 'メニューへ戻る',
      title_eng: 'Back to menu',
      key_code: '92',
      checked: false,
      focused: false
    }
  ];
  rental: Rental[] = require('./rental.json');
  appname: string;
  constructor(
    private roomNoService: RoomNoService,
    private router: Router,
    private languageService: LanguageService
  ) {}
  ngOnInit() {
    // 現在時刻の表示
    this.sub.push(
      this.now_date.subscribe(d => {
        this.now_date_obj = getNowDateObject(d, this.languageService.language);
      })
    );

    // Languageをサービスしちゃう
    this.lang = this.languageService.language;
    if (this.languageService.language === 'jpn') {
      this.appname = '客室でのくつろぎ方';
      return;
    } else {
      this.appname = 'Borrowing items';
    }
    this.sidebar = this.sidebar.map(d => {
      d.title = d.title_eng;
      return d;
    });
    this.rental = this.rental.map(d => {
      return {
        name: d.name_en,
        name_jp: d.name_jp,
        name_en: d.name_en,
        icon_url: d.icon_url
      };
    });
  }

  ngOnDestroy() {
    this.sub.forEach(d => d.unsubscribe());
  }

  setFocusIndex(index: number) {
    if (
      ['メニューへ戻る', 'Back to menu'].includes(this.sidebar[index].title)
    ) {
      this.router.navigate(['/room', this.roomNoService.room_no]);
    }
    this.focusIndex = index;
    this.sidebar.forEach(d => (d.checked = false));
    this.sidebar[this.focusIndex].checked = true;
    this.rentalIndex = index;
    //    this.rentalIndex = index;
  }
}

type AppListSideBarTiTleJpn =
  | '貸し出し品一覧'
  | '滞在清掃について'
  | 'モーニングコール'
  | 'あめ玉で楽しむ禅';

type AppListSideBarTiTleEng =
  | 'Borrowing Items'
  | 'Wake-up call'
  | 'Massage'
  | 'Zen';
declare function require(x: string): any;
