import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-appli-main',
  templateUrl: './appli-main.component.html',
  styleUrls: ['./appli-main.component.scss']
})
export class AppliMainComponent implements OnInit {
  @Input() appList: AppliMain[];
  @Output() changeFocus = new EventEmitter<number>();
  @Output() launchAppli = new EventEmitter<number>();
  @Output() resetAllFocus = new EventEmitter<void>();
  constructor() {}

  ngOnInit() {}

  focus(i: number) {
    this.changeFocus.emit(i);
    this.appList.forEach(d => (d.focused = false));
    this.appList[i].focused = true;
  }

  click(i: number) {
    this.launchAppli.emit(i);
  }

  blur() {
    this.resetAllFocus.emit();
  }
}
